<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <order-table type="dark" :title="$t('orders.orders')"></order-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderTable from "../Tables/Orders/OrderTable.vue";
export default {
  name: "Order",
  components: {
    OrderTable,
  },
};
</script>
<style></style>
