<template>
  <base-loader v-if="loading"></base-loader>
  <!-- Main Print -->
  <div
    v-if="orderInfo"
    class="print bg-white"
    :class="{ hidden: !shownInvoice }"
    id="printMe"
    ref="hide"
  >
    <div class="invoice-top-header p-4 d-flex justify-content-between">
      {{ $t("global.nqal") }}
      <div class="no-print">
        <button
          @click="printOrderInvoice()"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-print"></i> {{ $t("orders.print invoice") }}
        </button>
        <button
          @click="shownInvoice = false"
          class="btn btn-primary btn-sm print-btn"
        >
          <i class="fas fa-long-arrow-alt-left"></i> {{ $t("global.back") }}
        </button>
      </div>
    </div>
    <header class="pb-0 invoice-header">
      <div class="container pt-4 pb-0 px-4">
        <div class="row">
          <div class="col-md-4 mb-4">
            <!-- <qrcode-vue :value="qrValue" :size="70" level="H" v-if="qrValue" /> -->
            <span class="date">#{{ orderInfo.id }}</span>
            <span
              class="date"
              v-for="(status, index) in orderInfo.statuses"
              :key="index"
            >
              <span v-if="index == orderInfo.statuses.length - 1">{{
                status.name
              }}</span>
            </span>
            <span class="date d-flex">
              {{ $t("orders.delivery_price") }}:
              <p class="mb-0 mx-2">
                {{ orderInfo.price + " " + $t("orders.aed") }}
              </p>
            </span>
            <span class="date d-flex">
              {{ $t("global.payment method") }}:
              <p class="mb-0 mx-2">{{ orderInfo.payment_methods_name }}</p>
            </span>
            <p>{{ $t("global.use invoice num") }}</p>
          </div>
          <div class="col-md-4 d-flex justify-content-md-center mb-4">
            <div>
              <h6 v-if="orderInfo.order_type == 'send'">
                {{ $t("global.from") }}
              </h6>
              <h6 v-else-if="orderInfo.order_type == 'collect'">
                {{ $t("global.to") }}
              </h6>
              <div v-if="orderInfo.trader_detials">
                <span class="date">{{ orderInfo.trader_detials.name }}</span>
              </div>
              <div v-else-if="orderInfo.customer_detials">
                <span class="date">{{ orderInfo.customer_detials.name }}</span>
                <p class="mb-0">
                  {{ $t("tables.phone") }}:
                  {{ orderInfo.customer_detials.phone }}
                </p>
              </div>
              <span>{{ $t("tables.address") }}</span>
              <p class="mb-0">
                {{ orderInfo.address }}
              </p>
              <div :class="{ hidden: !printPDF }">
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.pickup_lat +
                    ',' +
                    orderInfo.pickup_long
                  "
                >
                  {{ $t("global.map_from") }}
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-4 d-flex justify-content-md-end mb-4">
            <div>
              <h6 v-if="orderInfo.order_type == 'send'">
                {{ $t("global.to") }}
              </h6>
              <h6 v-else-if="orderInfo.order_type == 'collect'">
                {{ $t("global.from") }}
              </h6>
              <span class="date">{{ orderInfo.receiver_name }}</span>
              <p class="mb-0">
                {{ $t("tables.phone") }}: {{ orderInfo.receiver_phone }}
              </p>
              <span>{{ $t("tables.address") }}</span>
              <p class="mb-0">
                {{ orderInfo.state }} / {{ orderInfo.receiver_address }}
              </p>
              <div :class="{ hidden: !printPDF }">
                <a
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    orderInfo.receiver_lat +
                    ',' +
                    orderInfo.receiver_long
                  "
                >
                  {{ $t("global.map_to") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="invoice-body mt-5 bg-white">
      <div class="container">
        <div class="table-responsive">
          <table class="table invoice-table">
            <thead>
              <tr class="border-0">
                <th>#</th>
                <th>{{ $t("global.image") }}</th>
                <th>{{ $t("form.name") }}</th>
                <th>{{ $t("orders.description") }}</th>
                <th>{{ $t("form.price") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in orderInfo.orderItems" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <img
                    :src="'https://nqal.co/LiveNqal/public/' + item.image"
                    :alt="item.title + ' Image'"
                  />
                </td>
                <td>{{ item.title }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.value }}</td>
              </tr>
              <!-- <tr class="total-invoice">
                <td colspan="2">SUPTOTAL<br /><strong>100</strong></td>
                <td colspan="2">TAX FEES (20%)<br /><strong>20</strong></td>
                <td colspan="3" class="last-td">
                  TOTAL<br /><strong>120</strong>
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <p class="mt-5">*{{ $t("global.use invoice num") }}</p>
      </div>
    </div>
    <footer class="mt-5 text-center py-2 invoice-footer bg-white">
      <p class="mb-0">{{ $t("global.thanks") }}</p>
      <div class="ar-ltr">
        <a href="#" class="mx-2"
          ><i class="fas fa-globe"></i> https://www.nqal.co</a
        >
        <!-- <a href="tel:01001010" class="mx-2"><i class="fas fa-phone"></i> 010101010</a> -->
        <a href="info@nqal.co" class="mx-2"
          ><i class="fas fa-envelope"></i> info@nqal.co</a
        >
      </div>
      <div v-if="orderInfo.order_serial" class="barcode-svg">
        <barcode-generator
          :value="orderInfo.order_serial"
          :format="'CODE39'"
          :lineColor="'#000'"
          :height="'40'"
          :width="'2'"
          :elementTag="'svg'"
        />
      </div>
    </footer>
  </div>
  <div>
    <div class="card shadow mb-4" :class="{ hidden: shownInvoice }">
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-default' : ''"
      >
        <div class="row align-items-center">
          <div class="col-md-4">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ $t("orders.order details") }}
            </h3>
          </div>
          <div class="col-md-8 text-right d-flex justify-content-end">
            <input
              :value="$t('orders.generate invoice')"
              type="button"
              @click="shownInvoice = true"
              class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
            />
            <button
              @click="checkPrintPDF()"
              class="btn btn-primary btn-sm print-btn mb-3 mb-lg-0"
            >
              <i class="fas fa-file-pdf"></i> {{ $t("orders.print_pdf") }}
            </button>
          </div>
        </div>
      </div>
      <div id="map" class="map-canvas" style="height: 600px"></div>
      <div class="table-responsive">
        <table class="table tablesorter table align-items-center table-flush">
          <!-- Table Head -->
          <tbody>
            <tr>
              <th>
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("form.order address") }}
              </th>
              <td>
                {{ orderInfo.address }}
              </td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-circle-08 mr-2 text-main"></i>
                {{ $t("form.receiver name") }}
              </th>
              <td>{{ orderInfo.receiver_name }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-mobile-button mr-2 text-main"></i>
                {{ $t("form.receiver phone") }}
              </th>
              <td>{{ orderInfo.receiver_phone }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-square-pin mr-2 text-main"></i>
                {{ $t("form.receiver address") }}
              </th>
              <td>
                {{ orderInfo.state + " - " + orderInfo.receiver_address }}
              </td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-money-bill-wave-alt mr-2 text-main"></i>
                {{ $t("form.price") }}
              </th>
              <td>{{ orderInfo.price }}</td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-box mr-2 text-main"></i>
                {{ $t("form.order_type") }}
              </th>
              <td>{{ orderInfo.order_type }}</td>
            </tr>
            <tr>
              <th>
                <i class="fas fa-credit-card mr-2 text-main"></i>
                {{ $t("form.payment method") }}
              </th>
              <td>{{ orderInfo.payment_methods_name }}</td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-user-run mr-2 text-main"></i>
                {{ $t("form.courier name") }}
              </th>
              <td>
                {{ orderInfo.courier_name + " - " + courier_phone }}
              </td>
            </tr>
            <tr>
              <th>
                <i class="ni ni-delivery-fast mr-2 text-main"></i>
                {{ $t("orders.shipping status") }}
              </th>
              <td class="ar-ltr">
                <span
                  v-for="(status, index) in orderInfo.statuses"
                  :key="index"
                >
                  <span
                    class="badge text-white"
                    :class="
                      index != orderInfo.statuses.length - 1
                        ? 'bg-danger'
                        : 'bg-success'
                    "
                  >
                    {{ status.name }}
                  </span>
                  <i
                    v-if="index != orderInfo.statuses.length - 1"
                    class="fas fa-arrow-right text-main mx-2"
                  ></i>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Order Items -->
    <div
      class="card shadow"
      :class="[type === 'dark' ? 'bg-default' : '', { hidden: shownInvoice }]"
    >
      <div
        class="card-header border-0"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      >
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
              {{ title }}
            </h3>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <base-table
          class="table align-items-center table-flush"
          :class="type === 'dark' ? 'table-dark' : ''"
          :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
          tbody-classes="list"
          :data="order"
        >
          <!-- Table Head -->
          <template v-slot:columns>
            <th>#</th>
            <th>{{ $t("tables.image") }}</th>
            <th>{{ $t("form.name") }}</th>
            <th>{{ $t("tables.price") }}</th>
            <th>{{ $t("tables.quantity") }}</th>
          </template>

          <!-- Table Body -->
          <template v-slot:default="row">
            <td class="budget">
              {{ row.item["item"].id }}
            </td>
            <td class="budget">
              <img
                :src="row.item['item'].images[0]"
                :alt="row.item['item'].title + ' Image'"
              />
            </td>
            <td class="budget">
              {{ row.item["item"].title }}
            </td>
            <td class="budget">
              {{ row.item["item"].price }}
            </td>
            <td class="budget">
              {{ row.item.quantity }}
            </td>
          </template>
        </base-table>
      </div>

      <div
        class="card-footer d-flex justify-content-end"
        :class="type === 'dark' ? 'bg-transparent' : ''"
      ></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BarcodeGenerator from "../../../components/BarcodeGenerator.vue";
import { db, doc, getDoc } from "../../../firebase/firestoreDb";
import { Loader } from "google-maps";
const loader = new Loader("AIzaSyDx-Roz0uBFP0w6YV2RMhYEwurSAVIH3IQ");
export default {
  name: "order-table",
  components: {
    BarcodeGenerator,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      shownInvoice: false,
      printPDF: false,
      courier: "",
      orderInfo: "",
      courier_phone: "",
      order: "",
    };
  },
  created() {
    this.getOrder();
    this.getOrderCourier();
  },
  methods: {
    getOrder: function () {
      this.loading = true;
      axios
        .get("admin/administration/orders/" + this.$route.params.id)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            let that = this;
            this.orderInfo = response.data.data[0];
            this.courier_phone = this.orderInfo.courier_detials.phone ?? "";
            this.order = response.data.data[0].items;
            loader.load().then(function (google) {
              // Regular Map
              const myLatlng = new google.maps.LatLng(41.85, -87.65); //Center
              const mapOptions = {
                zoom: 7,
                center: myLatlng,
                mapId: "2bf1cba222371325",
                scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
                disableDefaultUI: true, // a way to quickly hide all controls
                zoomControl: true,
              };

              var start = new google.maps.LatLng(
                response.data.data[0].pickup_lat,
                response.data.data[0].pickup_long
              );
              var end = new google.maps.LatLng(
                response.data.data[0].receiver_lat,
                response.data.data[0].receiver_long
              );

              var directionsService = new google.maps.DirectionsService();
              var directionsDisplay = new google.maps.DirectionsRenderer();

              const map = new google.maps.Map(
                document.getElementById("map"),
                mapOptions
              );
              if (that.courier) {
                const image =
                  "https://icons.iconarchive.com/icons/custom-icon-design/flatastic-2/48/truck-icon.png";

                new google.maps.Marker({
                  position: new google.maps.LatLng(
                    that.courier.lat,
                    that.courier.lng
                  ),
                  map: map,
                  icon: image,
                });
              }
              // new google.maps.Marker({
              //   icon: 'image',
              //   position: start,
              //   map: map,
              // });

              directionsDisplay.setMap(map);
              directionsService.route(
                {
                  origin: start,
                  destination: end,
                  travelMode: "DRIVING",
                },
                function (response, status) {
                  if (status === "OK") {
                    directionsDisplay.setDirections(response);
                  } else {
                    window.alert("Directions request failed due to " + status);
                  }
                }
              );
            });
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Print Invoice
    printOrderInvoice() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printMe").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
        <head>
            ${stylesHtml}
        </head>
        <body>
            ${prtHtml}
        </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.document.title = "invoice_" + this.orderInfo.id;
      WinPrint.print();
      //   WinPrint.close();
      this.printPDF = false;
    },
    // Print PDF
    checkPrintPDF() {
      this.printPDF = true;
      setTimeout(() => {
        this.printOrderInvoice();
      }, 100);
    },
    getOrderCourier: function () {
      (async () => {
        const docRef = doc(
          db,
          "FreelanceCouriersOrders",
          this.$route.params.id
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.courier = docSnap.data();
        } else {
          console.log("No such document!");
        }
      })();
    },
  },
};
</script>

<style lang="scss" scoped>
.closest-courier {
  text-align: start;
}
.closest-courier img {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.closest-courier h4 {
  font-size: 18px;
  font-weight: 700;
  border-radius: 50%;
}
.closest-courier p {
  font-size: 20px;
  font-weight: 600;
  margin-inline-start: 10px;
}
img {
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.hidden {
  display: none;
}
.invoice-top-header {
  background-color: #3a3d50;
  color: #fff;
  font-size: 20px;
}
.invoice-header > .container {
  background-color: #f7f7f7;
}
.invoice-header .row > div {
  width: 33%;
}
.invoice-header svg {
  font-size: 40px;
  margin-bottom: 10px;
}
.invoice-header h6 {
  color: #576677;
}
.invoice-header span {
  color: #576677;
  display: block;
}
.invoice-header span.date {
  font-weight: 600;
  font-size: 17px;
}
.invoice-header p {
  font-size: 500;
  color: #576677;
}
.invoice-table td,
.invoice-table th {
  vertical-align: middle;
  white-space: nowrap;
}
.invoice-body table img {
  max-width: 70px;
}
.invoice-body .total-invoice {
  background-color: #f7f7f7;
}
.invoice-body .total-invoice td.last-td {
  background-color: #3a3d50 !important;
  color: #fff;
  font-size: 22px;
}
.invoice-body > p {
  color: #555;
  font-weight: 600;
}
.invoice-footer {
  border-top: 1px solid #eee;
}
.invoice-footer a {
  color: #555;
}
.invoice-footer a svg {
  color: #576677;
}
</style>
